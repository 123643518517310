import {useEffect, useState} from "react";
import RecordingList from "./RecordingList";

function Recordings({fetchRecordings, recordings}) {
	const [offset, setOffset] = useState(0);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		(async () => {
			await fetchRecordings(offset);
		})();
	}, [offset]);

	return (
		<div className={"bg-white shadow-sm p-3"}>
			<h3 className={"text-center"}>Recordings</h3>
			{recordings.length > 0 ?
				<RecordingList recordings={recordings} loading={loading} setOffset={setOffset} fetchRecordings={fetchRecordings}/>
				:
				<p>Your recordings will appear here when you have some...</p>
			}
		</div>
	);
}

export default Recordings;