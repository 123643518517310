import {useEffect, useState} from "react";

function RecordingItem({recording, fetchRecordings}) {
	const [src, setSrc] = useState('');

	useEffect(() => {
		const byteArray = Uint8Array.from(recording.audioBlob.data);
		const blob = new Blob([byteArray],  { 'type' : 'audio/wav' });
		setSrc(URL.createObjectURL(blob));
	}, []);

	async function deleteRecording() {
		try {
			const response = await fetch(process.env.REACT_APP_endPoint + '/recordings/' + recording.id, {
				method: "DELETE"
			});
			fetchRecordings();
		}
		catch (e) {
			console.error(e);
		}
	}

	return (
		<tr>
			<td>{recording.id}</td>
			<td>{recording.phoneNumber}</td>
			<td><audio controls={true} src={src} /></td>
			<td>
				<button type={"button"} onClick={deleteRecording} className={"btn btn-sm btn-danger"}>Delete</button>
			</td>
		</tr>
	);
}

export default RecordingItem;