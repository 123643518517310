import RecordingItem from "./RecordingItem";

function RecordingList({recordings, setOffset, fetchRecordings, loading}) {
	return (
		<div style={{maxHeight: '50vh', overflowY: 'scroll'}} id={"table"} onScroll={(evt) => {
			if (evt.target.scrollTop + evt.target.clientHeight >= evt.target.scrollHeight) {
				console.log("Setting offset to " + recordings.length);
				setOffset(recordings.length);
			}
		}}>
			<table className={"table table-striped table-hover"}    >
				<thead className={"table-dark sticky-top"}>
				<tr>
					<th>#</th>
					<th>Phone number</th>
					<th>Audio</th>
					<td>Actions</td>
				</tr>
				</thead>
				<tbody>
				{recordings.map(rec => {
					return <RecordingItem recording={rec} key={rec.id} fetchRecordings={fetchRecordings}/>;
				})}
				</tbody>
			</table>
			{loading && <p>LOading....</p>}
		</div>

	)
}

export default RecordingList;