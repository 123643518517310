import {useRef, useState} from "react";
import { Device } from '@twilio/voice-sdk';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

(async () => {
	await register(await connect());
})();

function CallPerson({setRecordings}) {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [callDisabled, setCallDisabled] = useState(false);
	const [hangUpDisabled, setHangUpDisabled] = useState(true);
	const [callButtonText, setCallButtonText] = useState('Call');
	const [recordButtonText, setRecordButtonText] = useState('Start Recording');
	const mediaRecorder = useRef(null);
	const mediaStream = useRef(null);
	let chunks = useRef([]);
	const [audioObjectUrl, setAudioObjectUrl] = useState('');

	const hangUpButton = useRef(null);


	async function call(evt) {
		evt.preventDefault();
		try {
			mediaStream.current = null;
			const token = await generateToken();

			await navigator.mediaDevices.getUserMedia({
				audio: true
			});
			let devices = await navigator.mediaDevices.enumerateDevices();

			devices = devices.filter(value => value.kind === "audioinput");

			const device = new Device(token, {
				logLevel: 1
			});

			device.on("registered", (device) => {
				console.log("Twilio.Device Ready!");
				console.log(device);
			});

			device.on('registering', (device) => {
				console.log(device);
			});

			device.on('tokenWillExpire', (device) => {
				console.log(device);
			});

			device.on('unregistered', (device) => {
				console.log(device);
			});

			device.on("error", (error) => {
				console.log("Twilio.Device Error: " + error.message);
			});


			device.on("incoming", (conn) => {
				console.log(conn);
			});

			makeOutgoingCall();

			async function makeOutgoingCall() {
				const params = {
					To: phoneNumber,
				};

				const call = await device.connect({ params });


				setCallButtonText('Calling ....');
				setCallDisabled(true);
				setHangUpDisabled(false);

				call.on('volume', (vol1, vol2) => {
					if (vol1 > 0 && mediaStream.current === null) {
						mediaStream.current = call.getRemoteStream();
					}
				});
				call.on("accept", () => {
					setCallButtonText('In call');
				});
				call.on("disconnect", () => {
					setCallDisabled(false);
					setCallButtonText('Call');
					setHangUpDisabled(true);

					if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
						mediaRecorder.current.stop();
						setRecordButtonText('Start Recording');
					}

					mediaRecorder.current = null;
					chunks.current = [];
					mediaStream.current = null;
				});
				call.on("cancel", () => {
					setCallDisabled(false);
					setCallButtonText('Call');
					setHangUpDisabled(true);

					if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
						mediaRecorder.current.stop();
						setRecordButtonText('Start Recording');
					}

					mediaRecorder.current = null;
					chunks.current = [];
					mediaStream.current = null;
				});

				hangUpButton.current.onclick = () => {
					call.disconnect();
				};

			}
		}
		catch (e) {
			console.error(e);
		}
	}


	async function handleChunks(evt) {
		if (evt.data.size > 0) {
			try {
				const formData = new FormData();

				formData.append('recording', evt.data);
				formData.append('phoneNumber', phoneNumber);
				const response = await fetch(process.env.REACT_APP_endPoint + '/recordings', {
					method: "POST",
					body: formData
				});
				const {recording} = await response.json();

				setRecordings((prevState) => {
					return [recording, ...prevState];
				});
			}
			catch (e) {
				console.error(e);
			}
		}
	}

	function record(evt) {
		const start = recordButtonText === 'Start Recording';
		const stop = recordButtonText === 'Stop Recording';

		if (!mediaStream.current) {
			return evt.preventDefault();
		}
		try {
			if (start) {
				console.log(mediaStream.current);

				mediaRecorder.current = new MediaRecorder(mediaStream.current, {
					mimeType: 'audio/wav'
				});

				mediaRecorder.current.ondataavailable = handleChunks;

				mediaRecorder.current.start();
			}
			else {
				mediaRecorder.current.stop();
			}
		}
		catch (e) {
			console.error(e);
		}

		if (start) {
			setRecordButtonText('Stop Recording');
		}
		else {
			setRecordButtonText('Start Recording');
		}
	}

	async function generateToken() {
		const response = await fetch(process.env.REACT_APP_endPoint + '/token', {
			method: "POST"
		});
		const json = await response.json();
		return json.token;
	}

	return (
		<form onSubmit={call} className={"bg-white shadow-sm p-3"}>
			<div className="mb-3">
				<label htmlFor="phoneNumber">Phone number:</label>
				<input type="text" id={"phoneNumber"} className={"form-control"} value={phoneNumber} onChange={(evt) => {
					setPhoneNumber(evt.target.value);
				}}/>
			</div>

			<audio src={audioObjectUrl}></audio>
			<div className="d-flex justify-content-between">
				<button type={"submit"} className={"btn btn-primary"} disabled={callDisabled}>{callButtonText}</button>
				<button type={"button"} className={"btn btn-danger"} disabled={hangUpDisabled} ref={hangUpButton}>Hang up</button>
			</div>

			<button onClick={record} type={"button"} className={"btn btn-warning"}>{recordButtonText}</button>
		</form>
	)
}

export default CallPerson;