import Recordings from "./components/Recordings";
import CallPerson from "./components/CallPerson";
import {useState} from "react";

function App() {
	const [recordings, setRecordings] = useState([]);

	async function fetchRecordings(offset) {
		try {
			const response = await fetch(process.env.REACT_APP_endPoint + '/recordings?offset=' + offset);
			const json = await response.json();
			setRecordings((prevRecordings) => {
				return [...prevRecordings, ...json.recordings]
			});
		}
		catch(e) {
			console.error(e);
		}
	}

	return (
		<div className={"container mt-5"}>
			<h1 className={"text-center"}>Nuance POF</h1>

			<CallPerson setRecordings={setRecordings}/>
			<hr />
			<Recordings fetchRecordings={fetchRecordings} recordings={recordings}/>
		</div>
	);
}

export default App;
